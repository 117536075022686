// eslint-disable-next-line import/no-extraneous-dependencies
import Swiper, { Navigation, Mousewheel, Pagination } from 'swiper';
import { setMaxHeightTitle, setMaxWidthFrameDescr, checkDeviceWidth, getScaleWidth } from './main-utils';

function init(container: HTMLElement | Document = document) {
    let navigationContainer;

    const originalNavContainer = container.querySelector('.js-main-events-nav');
    const mobileNavContainer = container.querySelector('.js-main-events-mobile-nav');
    let nav = {};

    if (checkDeviceWidth() < 767 && mobileNavContainer) {
        navigationContainer = mobileNavContainer;
    } else if (originalNavContainer) {
        navigationContainer = originalNavContainer;
    }

    if (navigationContainer) {
        nav.prev = navigationContainer.querySelector<HTMLElement>('.js-slider-prev');
        nav.next = navigationContainer.querySelector<HTMLElement>('.js-slider-next');
        nav.dots = container.querySelector<HTMLElement>('.js-slider-dots');
    }

    const slidesLength = Array.from(container.querySelectorAll('.swiper-slide')).length;
    let isNeedToInitSwiper = false;

    const checkIsNeedToInitSwiper = () => {
        if (window.matchMedia('(min-width: 1280px)').matches) {
            isNeedToInitSwiper = slidesLength > 3;
        } else if (window.matchMedia('(max-width: 1279px) and (min-width: 768px)').matches) {
            isNeedToInitSwiper = slidesLength > 2;
        } else {
            isNeedToInitSwiper = slidesLength > 1;
        }

        if (isNeedToInitSwiper) {
            container.classList.add('navigation-show');
        } else {
            container.classList.remove('navigation-show');
        }
    };

    checkIsNeedToInitSwiper();
    window.addEventListener('resize', checkIsNeedToInitSwiper);

    if (isNeedToInitSwiper) {
        const scaleWidth = getScaleWidth(container);

        const sliderWrapper = container.querySelector('.js-mp__slider-wrapper');
        const sliderWrapperWidth = sliderWrapper?.offsetWidth;
        const containerMaxHeight = container?.offsetHeight;

        if (checkDeviceWidth() > 767 && containerMaxHeight) {
            const maxHeightWrapper = (container.style.minHeight = containerMaxHeight + scaleWidth + 'px');
        }
        if (checkDeviceWidth() > 1199) {
            setMaxWidthFrameDescr(container);
        }

        const instance = new Swiper(container as HTMLElement, {
            modules: [Navigation, Mousewheel, Pagination],
            speed: 600,
            slidePrevClass: 'is-prev',
            slideActiveClass: 'is-active',
            slideNextClass: 'is-next',
            slidesPerView: 'auto',
            mousewheel: false,
            breakpoints: {
                1920: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 2,
                },
                320: {
                    slidesPerView: 1,
                },
            },
            navigation: {
                nextEl: nav.next,
                prevEl: nav.prev,
                disabledClass: 'is-disable',
            },
            pagination: {
                el: nav.dots,
                type: 'bullets',
                clickable: true,
                bulletClass: 'dots__item',
                bulletActiveClass: 'is-active',
                renderBullet: (index, className) => {
                    return '<span class="' + className + '">' + '</span>';
                },
            },
            on: {
                init: () => {
                    if (originalNavContainer && mobileNavContainer) {
                        for (let i = 0; i < originalNavContainer.children.length; i++) {
                            mobileNavContainer.appendChild(originalNavContainer.children[i].cloneNode(true));
                        }
                        const prevButton = mobileNavContainer.children[0];
                        const nextButton = mobileNavContainer.children[1];
                        if (prevButton) {
                            prevButton.addEventListener('click', () => {
                                instance.slidePrev();
                            });
                        }
                        if (nextButton) {
                            nextButton.addEventListener('click', () => {
                                instance.slideNext();
                            });
                        }
                    }
                    if (container.classList.contains('js-large-active-slide')) {
                        if (checkDeviceWidth() > 1199) {
                            setMaxWidthFrameDescr(container);
                        }
                    } else {
                        setMaxHeightTitle(container);
                    }
                },
            },
        });
    }
}

export default { init };
